body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: #33ccff #09253E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img[alt=small_picture] {
    max-width: 10%;
    display: block;
    margin-left: auto;
    margin-right: auto
}

img[alt=medium_picture] {
    max-width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto
}

img[alt=big_picture] {
    max-width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto
}

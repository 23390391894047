::-webkit-scrollbar {
  background-color: #1C446D;
}

::-webkit-scrollbar-thumb {
  background-color: #90CAF9;
  border-radius: 6px;
  border-left: 3px solid #1C446D;
  border-right: 3px solid #1C446D;
}

.deck-tooltip {
  border-radius: 10px;
  border: 2px solid #134668;
  background-color: #2076B0;
}

.toggleHover:hover {
  background-color: #2277B1 !important;
}

.toggleNoHover:hover {
  background-color: #1E446D !important;
}

.lightbox {
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 25px 5px #2177b1;
  }
}

.responsive-img {
  max-width: 100%;
  height: auto;
}

.footer-img {
  padding: 20px;
  display: inline-block;
}

